import {IOrderCreateDto} from "../interfaces/order/IOrderCreateDto";
import {NetworkService} from "./NetworkService";
import {IOrderFilter} from "../interfaces/order/IOrderFilter";
import {Order} from "../models/order/Order";
import {IOrderConstructor} from "../interfaces/order/IOrderConstructor";
import {IOrderCreatedDto} from "../interfaces/order/IOrderCreatedDto";
import {IOrderState} from "../interfaces/order/IOrderState";
import {IEso9OrderDto} from "../interfaces/order/IEso9OrderDto";

const controller = "/order";

class OrderService {
    async create(order: IOrderCreateDto): Promise<IOrderCreatedDto> {
      const {data} =  await NetworkService.newConnection().post<IOrderCreatedDto>(controller, order);
      return data;
    }

    async update(order: IOrderCreateDto, orderId:number): Promise<void> {
        await NetworkService.newConnection().put<IOrderCreatedDto>(controller + `/${orderId}`, order);

    }

    async filter(filter:IOrderFilter): Promise<Order[]>{


        let str = `?regionId=${filter.regionId}`;
        str +=`&customerId=${filter.customerId ?? ''}`;
        str +=`&storeId=${filter.storeId ?? ''}`;
        str +=`&createdFrom=${filter.createdFrom ?? ''}`;
        str +=`&createdTo=${filter.createdTo ? filter.createdTo + " 23:59:59"  : ''}`;
        str +=`&ordernumber=${filter.orderNumber ?? ''}`;
        str +=`&userId=${filter.userId ?? ''}`;
        if(filter.stateIds.length > 0 ){
            for (let i = 0; i < filter.stateIds.length; i++) {
                str += '&stateIds=' + filter.stateIds[i];
            }
        }
        const {data} = await NetworkService.newConnection().get<IOrderConstructor[]>(controller +  str);
        return data.map(x => new Order(x));

    }

    async detail(id:number):Promise<Order>{
        const {data} = await NetworkService.newConnection().get<IOrderConstructor>(controller + `/${id}`);
        return new Order(data);
    }

    async syncOutOrder(orderId:number, countryCode:string):Promise<void>{
        await NetworkService.newConnection().put(controller + `/${orderId}/country/${countryCode}/sync/out`);
    }

    async syncInOrder(orderId:number, countryCode:string):Promise<void>{
        await NetworkService.newConnection().get(controller + `/${orderId}/country/${countryCode}/sync/in`);
    }

    async loadFreshOrderDataFromErp(orderId:number, countryCode:string):Promise<IEso9OrderDto>{
        const {data} = await NetworkService.newConnection().get<IEso9OrderDto>(`${controller}/${orderId}/country/${countryCode}`);
        return data;
    }


    async syncOrdersFromErp(countryCode: string ,from: string, auto: boolean):Promise<any>{
        await NetworkService.newConnection().get(`${controller}/country/${countryCode}/sync/in?from=${from}&auto=${auto}`);
    }

    async cancelOrder(orderId:number): Promise<void>{
        await NetworkService.newConnection().put(controller + `/${orderId}/storno`);
    }

    async getBase64(guid: string) :Promise<any>{
        var result = await NetworkService.newConnection().get<any>(`${controller}/${guid}/base64`);
        return result.data;
    }

     returnOrderStateChipColor (stateId:number): "default" | "primary" | "warning" | "secondary" | "success" | "error" | "info" | undefined  {
        switch (stateId) {
            case 1:
                return 'primary';
            case 2:
                return 'error';
            case 3:
                return 'warning';
            case 5:
                return 'warning';
            case 6:
                return 'warning';
            case 7:
                return 'success';
            default:
                return 'primary';
        }
    }
     returnOrderStates():IOrderState[]{
        return(
            [
                {id: 1, name: 'Nová', note: null},
                {id:2, name: 'Storno', note: null},
                {id:3, name: 'Importováno - ERP', note: null},
                {id:4, name: 'N/A', note: null},
                {id:5, name: 'Založeno - ERP', note: null},
                {id:6, name: 'K vychystání - ERP', note: null},
                {id:7, name: 'Uzavřeno - ERP', note: null},
                {id:9, name: 'Zrušeno - ERP', note: null},
                {id:10, name: 'Rozpracováno - ERP', note: null},

            ]
        )
    }
     returnOrderState(id:number):IOrderState{
        let states = this.returnOrderStates();
        return states.find(state => state.id === id) || {id: 0, name: 'Unknown', note: null};
    }


    async  upload(orderId: number, attachment: File): Promise<void> {
        const formData = new FormData();
        formData.append("file", attachment, attachment.name);
        await NetworkService.newConnection().post(
            `${controller}/${orderId}/attachment`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
    }

    async  deleteAttachment(orderId: number, guid: string): Promise<void> {

        await NetworkService.newConnection().delete(
            `${controller}/${orderId}/attachment/${guid}`,
        );
    }



}



const orderService = new OrderService();

export default orderService;