import {IOrderAttachment} from "../../interfaces/order/IOrderAttachment";

const linkToAzure = process.env.REACT_APP_AZURE_BLOB_ENDPOINT + "/" + process.env.REACT_APP_AZURE_SELLORDERS_CONTAINER + "/";

export class OrderAttachment {
    originalFileName: string;
    fileExtension: string;
    contentType: string;
    sizeInBytes: number;
    link: string;
    guid: string;

    constructor(data: IOrderAttachment) {
        this.originalFileName = data.originalFileName;
        this.fileExtension = data.fileExtension;
        this.contentType = data.contentType;
        this.sizeInBytes = data.sizeInBytes;
        this.guid = data.guid;
        this.link = linkToAzure + data.guid + data.fileExtension;
    }
}