import React, {useEffect} from "react";
import {
    Alert,
    Box, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography, useTheme
} from "@mui/material";
import {OrderForm} from "./OrderForm";
import {IDialogBase} from "../../../interfaces/IDialogBase";
import {ButtonBasic} from "../../shared/ButtonBasic";
import {IOrderConstructor} from "../../../interfaces/order/IOrderConstructor";
import {Order} from "../../../models/order/Order";
import orderService from "../../../services/OrderService";
import {useTranslation} from "react-i18next";
import {IOrderCreatedDto} from "../../../interfaces/order/IOrderCreatedDto";
import {AlertAppContext} from "../../alert/AlertAppContext";


interface IProps extends IDialogBase {
    onOrderCreate: (id:number) => void
    editId: number

}



export const OrderFormDialog: React.FC<IProps> = (props) => {

    const [loaded, setLoaded] = React.useState<boolean>(props.editId === 0 ? true : false);
    const [actionLoading, setActionLoading] = React.useState<boolean>(false);
    const theme = useTheme();
    const {t} = useTranslation();
    const appAlert = React.useContext(AlertAppContext);



    //todo: O order state z db
    const orderInitialState:IOrderConstructor = {
        id: 0,
        noteInternal: null,
        noteCustomer: null,
        customer: null,
        customerAddress: null,
        username: null,
        items: [],
        erpStore: null,
        orderNumber: null,
        createdAt: null,
        customerOrderNumber: null,
        orderState: {
            id: 1,
            name: 'Nová',
            note: null
        },
        userCreated: {
            id: 0,
            email: "",
            username: "",
            firstName: "",
            note: "",
            isDisabled: false,
            roles: [],
            lastName: "",
            position: ""
        },
        orderErps: null,
        orderErpsLastSync: null,
        attachments: []
    };

    const [order, setOrder] = React.useState<Order>(() => new Order(orderInitialState));
    const [orderCreated, setOrderCreated] = React.useState<IOrderCreatedDto|null>(null);

    console.log(order);



    const handleSave = () => {
        setActionLoading(true);
        order.validate();
        if(!order.isValid){
            appAlert.showAlert({severity: 'warning', message: 'Formulář není validní.'})
            setActionLoading(false);
            return false;
        }


        if(props.editId > 0){

            orderService.update(order.returnCreateDto(), props.editId).then(() => {
                setOrderCreated({
                     orderId: props.editId
                });

            }).catch(() => {
                //todo: O hlaska
            }).finally(() => setActionLoading(false));
        } else {
            orderService.create(order.returnCreateDto()).then((res) => {
                setOrderCreated(res);

            }).catch(() => {
                //todo: O hlaska
            }).finally(() => setActionLoading(false));
        }


    }


    const handleShowCreated = () => {
        if(orderCreated === null) return;
        //reset form
        setOrder(new Order(orderInitialState));
        props.onOrderCreate(orderCreated.orderId);
        setOrderCreated(null);
    }


    useEffect(() => {

        if(loaded) return;

            if (props.editId === 0 ) {
                setOrder(new Order(orderInitialState));
                setLoaded(true);

            } else {
                orderService.detail(props.editId).then((res)=> {
                    setOrder(res);
                    setLoaded(true);
                })
            }

    }, [loaded, orderInitialState, props.editId]);

    return (
        <Dialog open={props.open}
                    onClose={props.onClose}
                    fullWidth={true}
                    maxWidth="xl"

        >
            <DialogTitle sx={{
                p: 0

            }}>

                <Typography variant={'paperHeading'} >
                    {t('orders.form.newOrder')}
                </Typography>
            </DialogTitle>
            <DialogContent sx={{padding: 0, width:'100%', maxWidth: '100%'}}>

                {loaded ?   <OrderForm key={order.id}  loaded={loaded} setLoaded={(v:boolean) => setLoaded(v)} order={order} setOrder={(o:Order) => setOrder(o)} /> : <CircularProgress />  }



            </DialogContent>
            <DialogActions  sx={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column',padding: 3, backgroundColor: theme.palette.lightBlue.light}}>
                {actionLoading ? <CircularProgress /> :
                <Box display="flex" justifyContent="flex-end" mb={2}>
                    <ButtonBasic appVariant="close" onClick={() => {props.onClose(false)}} mr={2} />
                    {orderCreated ? null :
                        <ButtonBasic appVariant="save" onClick={handleSave} />
                    }

                </Box>
                }

                <Box display="flex" flexDirection="column" >
                    {orderCreated ?
                        <Alert severity="success" >
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                <Box>
                                    Objednávka byla úspešně {props.editId > 0 ? 'uložena.' : 'založena.'}.
                                </Box>
                                <Box onClick={handleShowCreated} sx={{marginLeft: 2,color: theme.palette.lightBlue.main, cursor: 'pointer', fontWeight: 'bold'}} >
                                   ZOBRAZIT
                                </Box>
                            </Box>

                        </Alert>
                        : null}

                </Box>
            </DialogActions>
        </Dialog>
    )

}