import React, {useEffect} from "react";
import {
    Box,
    Button,
    Chip, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useTheme
} from "@mui/material";
import {Order} from "../../models/order/Order";
import {IDialogBase} from "../../interfaces/IDialogBase";
import {OrderDetail} from "./OrderDetail";
import orderService from "../../services/OrderService";
import {ButtonBasic} from "../shared/ButtonBasic";
import LaunchIcon from "@mui/icons-material/Launch";
import {DoNotDisturb} from "@mui/icons-material";
import {AlertAppContext} from "../alert/AlertAppContext";
import {BasicConfirmDialog} from "../shared/BasicConfirmDialog";
import {IBasicConfirmDialog} from "../../interfaces/IBasicConfirmDialog";
import SyncIcon from '@mui/icons-material/Sync';
import {IEso9OrderDto} from "../../interfaces/order/IEso9OrderDto";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {OrderDetailSyncBox} from "./OrderDetailSyncBox";
import EditIcon from '@mui/icons-material/Edit';

interface IProps extends IDialogBase {
    orderId: number|null
    refresh: () => void
    onEditOrder: (id:number) => void
}


export const OrderDetailDialog: React.FC<IProps> = (props) => {
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [actionLoading, setActionLoading] = React.useState<boolean>(false);
    const [confirm, setConfirm] = React.useState<IBasicConfirmDialog>({
        title: '',
        message: ''
    });
    const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);
    const theme = useTheme();
    const appAlert = React.useContext(AlertAppContext);

    const [order, setOrder] = React.useState<Order|null>(null);
    const [orderFreshErpData, setOrderFreshErpData] = React.useState<IEso9OrderDto|null>(null);

    const updateFromErpInStates = [3,4,5,6,7,9,10];

    const handleSendOrder = () => {
        setActionLoading(true);
        orderService.syncOutOrder(order?.id ?? 0, 'cz').then( () => {
        props.refresh();

        }).catch(() => {
            //todo: O nefunguje error
            appAlert.showAlert({severity: "error", message: null})
        }).finally(() => {
            setActionLoading(false);
            setLoaded(false);
        });
    }

    const handleUpdateOrderFromEso = () => {
        setActionLoading(true);
        orderService.syncInOrder(order?.id ?? 0, 'cz').then( () => {
            props.refresh();

        }).catch(() => {
            //todo: O nefunguje error
            appAlert.showAlert({severity: "error", message: null})
        }).finally(() => {
            setActionLoading(false);
            setLoaded(false);
        });
    }

    const handleFreshDataFromEso = () => {
        setActionLoading(true);
        orderService.loadFreshOrderDataFromErp(order?.id ?? 0, 'cz').then( (res) => {
            setOrderFreshErpData(res);

        }).catch(() => {
            //todo: O nefunguje error
            appAlert.showAlert({severity: "error", message: null})
        }).finally(() => {
            setActionLoading(false);
        });
    }

    const handleEditOrder = () => {
        props.onClose(false);
        props.onEditOrder(props.orderId ?? 0);
    }


    const cancelOrder = () => {
        setActionLoading(true);
        orderService.cancelOrder(order?.id ?? 0).then( () => {
            appAlert.showAlert({severity: "success"});
            props.refresh();
        }).catch((err) => {
            appAlert.showAlert({severity: "error", message: err?.message ?? null})
        }).finally(() => {
            setActionLoading(false);
            setLoaded(false);
        });
    }

    const handleCancelButtonClick = () => {
        setConfirm({message: 'Opravdu stornovat objednávku?', title: 'STORNO OBJEDNÁVKY'});
        setOpenConfirm(true);

    }




    useEffect(() => {
        if(!loaded && props.orderId){
            orderService.detail(props.orderId).then((res) => {
                setOrder(res);
            }).catch((err) => {
                console.error(err);
            }).finally(() => setLoaded(true));
        }
    }, [loaded])

    return(
        <Dialog open={props.open}
                onClose={props.onClose}
                fullWidth={true}
                maxWidth="xl"
        >
            <DialogTitle sx={{
                p: 0

            }}>
            <Box display="flex"   flexDirection="column" p={2}>
                <Box display="flex" flexDirection="row" alignItems="center" >
                    <Chip color={orderService.returnOrderStateChipColor(order?.orderState.id ?? 0)} label={order?.orderState.name} sx={{marginRight: 2}} />
                    <Typography >  {order ? order.orderErp?.orderNoInErp : null}</Typography>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center" mt={1}>
                    <Typography variant="note">{order?.orderState.note}</Typography>
                </Box>
            </Box>


            </DialogTitle>
            <DialogContent sx={{padding: 0, width:'100%', maxWidth: '100%'}}>

                {order  && order.orderErpLastSync && order.orderErpLastSync.length > 0 ?
                    <Box p={2}>
                        {order.orderErpLastSync.map(((x,i) =>
                                <OrderDetailSyncBox lastSync={x} key={i} />
                        ))}
                    </Box>

                : null}
                <OrderDetail order={order}  loaded={loaded} onRefresh={() => {setLoaded(false)}} />
            </DialogContent>
            <DialogActions  sx={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column',padding: 3, backgroundColor: theme.palette.lightBlue.light}}>
                {actionLoading ? <CircularProgress /> :

                    <Box display="flex" justifyContent="flex-end" mb={2}>
                        <ButtonBasic appVariant="close" onClick={() => {props.onClose(true)}} mr={2} />

                        {/*  storno  */}
                        {order?.orderState.id === 1 ?
                            <Button  sx={{marginRight: 2}} color="warning" startIcon={<DoNotDisturb />} variant="outlined" onClick={handleCancelButtonClick}>Stornovat</Button>
                            : null}

                        {/*  storno  */}
                        {order?.orderState.id === 1 ?
                            <Button  sx={{marginRight: 2}} color="info" startIcon={<EditIcon />} variant="outlined" onClick={handleEditOrder}>Editovat</Button>
                            : null}

                        {/*  odeslat do ERP  */}
                        {order?.orderState.id === 1 ?
                            <Button  sx={{marginRight: 2}} startIcon={<LaunchIcon />} variant="contained" onClick={handleSendOrder}>Odeslat do ERP</Button>
                            : null}

                        {/*  aktualizovat z ERP  */}
                        {order != null &&  updateFromErpInStates.includes(order.orderState.id)  ?
                            <Button  sx={{marginRight: 2}} startIcon={<SyncIcon />} variant="contained" onClick={handleUpdateOrderFromEso}>Aktualizovat z ERP</Button>
                            : null}

                        {/*  info z ERP  */}
                        {order != null &&  order.orderState.id > 2  ?
                            <Button  sx={{marginRight: 2}} startIcon={<VisibilityIcon />} variant="outlined" onClick={handleFreshDataFromEso}>Zobrazit data z ERP</Button>
                            : null}




                    </Box>

                }

                {orderFreshErpData ?
                    <Box display="flex" justifyContent="flex-end" mb={2} mt={2} style={{color: 'grey'}}>
                        <div style={{marginLeft: 5}}>ID ERP:</div>
                        <div style={{marginLeft: 5}}>{orderFreshErpData.idHdok ?? 'N/A'}</div>
                        <div style={{marginLeft: 5}}>Číslo obj.:</div>
                        <div style={{marginLeft: 5}}> {orderFreshErpData.cis_Dok ?? 'N/A'}</div>
                        <div style={{marginLeft: 5}}>K úhradě:</div>
                        <div style={{marginLeft: 5}}> {orderFreshErpData.hDKuhradeVal ?? 'N/A'}</div>
                        <div style={{marginLeft: 5}}>Stav:</div>
                        <div style={{marginLeft: 5}}>{orderFreshErpData.txtStavHdok ?? 'N/A'}</div>
                        <div style={{marginLeft: 5}}>Stav ID:</div>
                        <div style={{marginLeft: 5}}>{orderFreshErpData.vlStavHdok ?? 'N/A'}</div>
                        <div style={{marginLeft: 5}}>ID intranet:</div>
                        <div style={{marginLeft: 5}}>{orderFreshErpData.idIntranet ?? 'N/A'}</div>
                        <div style={{marginLeft: 5}}>Počet položek:</div>
                        <div style={{marginLeft: 5}}>{orderFreshErpData.pocetSdok ?? 'N/A'}</div>


                    </Box>


                    : null}

            </DialogActions>
            <BasicConfirmDialog open={openConfirm} onClose={() => setOpenConfirm(false)} onConfirm={cancelOrder} data={confirm} />
        </Dialog>

    )
}